import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
 
 
 
 
export default function HistoricoVencido({
  historicoVencido
}: {
  historicoVencido
  : any
}) {
  const { t } = useTranslation();
 
  return (
    <Panel header={t('historicoVencido')} style={{width:'98%'}}>
       
       {historicoVencido.columns && (
        <DataTable rowGroupMode="rowspan" groupRowsBy="doc" size="small" value={historicoVencido.data} tableStyle={{ minWidth: '50rem' }}>
          {historicoVencido.columns.map((col:any) => (
                      <Column key={col.field} field={col.field} header={col.header} />
                  ))}
        </DataTable>
       )}
      <p>{historicoVencido.message}</p>
    </Panel>
  )
}