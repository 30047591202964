
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function Endividamento({
  endividamentos
}: {
  endividamentos
  : any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('endividamento')} style={{width:'98%'}}>
       
       <DataTable rowGroupMode="rowspan" groupRowsBy="documento" size="small" value={  endividamentos
} tableStyle={{ minWidth: '50rem' }}>
          <Column field="documento" header={t('documento')}></Column>
          <Column field="vencimento" header={t('vencimento')}></Column>
          <Column field="quantidade" header={t('quantidade')}></Column>
          <Column field="valor" header={t('valor')}></Column>
          <Column field="saldo" header={t('saldo')}></Column>
          <Column field="percentual" header={t('percentual')}></Column>
      </DataTable>
    </Panel>
  )
}
