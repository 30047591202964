import { useTranslation } from "react-i18next";
import { ScrollTop } from 'primereact/scrolltop';
import { Button } from 'primereact/button';
import DadosConsulta from './resposta/DadosConsulta';
import EnderecoHist from './resposta/EnderecoHist';
import HistoricoInfo from './resposta/HistorioInfo';
import ListaConsulta from './resposta/ListaConsulta';
import Empresas from './resposta/Empresas';
import Acoes from './resposta/Acoes';
import Score from './resposta/Score';
import CCFS from "./resposta/CCFS";
import Apontamentos from "./resposta/Apontamentos";
import Cadastral from "./resposta/Cadastral";
import AcoesCompleta from "./resposta/AcoesCompleta";
import Informativo from "./resposta/Informativo";
import CompletaPF from "./resposta/CompletaPF";
import Negativacoes from "./resposta/Negativacoes";
import Pendencias from "./resposta/Pendencias";
import Protestos from "./resposta/Protestos";
import RendaPresumida from "./resposta/RendaPresumida";
import CompletaPJ from "./resposta/CompletaPJ";
import DadosConsultaPJ from "./resposta/DadosConsultaPJ";
import Socios from "./resposta/Socios";
import {useRef, useState } from "react";
import {useReactToPrint} from "react-to-print"
import React from "react";
import { Toolbar } from "primereact/toolbar";
import Aviso from "./resposta/Aviso";
import FaturamentoPresumido from "./resposta/FaturamentoPresumido";
import IndicadorPJ from "./resposta/IndicadorPJ";
import LimiteCreditoPJ from "./resposta/LimiteCreditoPJ";
import MaisNegocio from "./resposta/MaisNegocio";
import ScoreGrauAtvidadePJ from "./resposta/ScoreGrauAtividadePJ";
import ParticipacaoEmpresa from "./resposta/ParticipacaoEmpresa";
import ComportamentoFinanceiro from "./resposta/ComportamentoFinanceiro";
import LinhaCredito from "./resposta/LinhaCredito";
import Endividamento from "./resposta/Endividamento";
import PontualidadePagamento from "./resposta/PontualidadePagamento";
import HistoricoPagamento from "./resposta/HistoricoPagamento";
import Analise from "./resposta/Analise";
import postConsultaWebService from '../../service/postConsultaWeb/postConsultaWebService';
import { FilterWeb } from "../../interface/FilterWeb";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";
import ProtestoQuod from "./resposta/ProtestoQuod";
import IndicadorNegocio from "./resposta/IndicadorNegocio";
import { Card } from "primereact/card";
import getzipResposta from "../../assets/getzipresposta.png"
import quodImg from "../../assets/quod.png"
import HistoricoVencido from "./resposta/HistoricoVencido";


export default function ConsultaPage({
  resposta,
  onClose,
  original
}: {
  resposta: any
  onClose: () => void,
  original: boolean
}) {
  const { t } = useTranslation();
  const print = useRef(null);
  const [consultouProtesto, setConsultouProtesto] = useState<boolean>(false);
  const [consultouPendencia, setConsultouPendencia] = useState<boolean>(false);
  const {state} = useGlobalState();
  const handlePrint = useReactToPrint({
    content : () => print.current,
   
  })
   const handleConsulta = (opcao:string)  =>  {
    const filterWeb : FilterWeb = {
      documento: resposta.informativo.documento.replaceAll('.','').replaceAll('-', '').replaceAll('/', '') + '',
      consultas: [opcao],
      valor: undefined,
      arrojado: undefined,
      usual: undefined,
      conservador: undefined,
      indicadores: []

    }
    postConsultaWebService(filterWeb)
    .then((data:any) => {
      window.scrollTo(0, 0);
      data.dadosConsulta = resposta.dadosConsulta
      data.dadosConsultaPJ = resposta.dadosConsultaPJ
      localStorage.setItem('resposta', JSON.stringify(data));
      window.open('./consultar','_blank')
    })
  }
  const startContent = (
    <React.Fragment>
        <Button label="Imprimir" icon="pi pi-print" style={{backgroundColor:'#183462'}} onClick={() => {handlePrint()}}/>
        { original && state.user.funcoes.includes('PROTESTO_TOOLSDATA') && (<Button disabled={consultouProtesto} label="Protesto Nacional - CENPROT" icon="pi pi-search-plus" style={{backgroundColor:'#183462', marginLeft: '2.5rem'}} onClick={() => {setConsultouProtesto(true); handleConsulta('PROTESTO_TOOLSDATA')}}/>)} 
        { original && state.user.funcoes.includes('PENDENCIA_TOOLSDATA') && (<Button disabled={consultouPendencia} label="Restrições do Mercado" icon="pi pi-search-plus" style={{backgroundColor:'#183462', marginLeft: '2.5rem'}} onClick={() => {setConsultouPendencia(true); handleConsulta('PENDENCIA_TOOLSDATA')}}/>)}  
    </React.Fragment>
);

const endContent = (
  <React.Fragment>
      <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />  
  </React.Fragment>
);

const razaoSocialOuNome = '';
const rodapeMensagem = `ATENÇÃO: As informações deste relatório são confidenciais e deverão ser utilizadas exclusivamente para orientação das transações comerciais, responsabilizando-se civil e criminalmente por danos causados a terceiros, quando da utilização em desacordo com a legislação em vigor.`;

return (<>
  <Toolbar style={{width:'98%'}} start={startContent} end={endContent}/>
  <br></br>
  <br></br>
    <div ref={print} className="flex flex-column align-items-center" >
    <style type="text/css" media="print">{"\
  @page {\ size: portrait; font-size: 6pt; .label-dados {font-size: 6pt;}; margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;\ }\
"}</style>
      <br></br>
      <Card style={{width:'98%'}}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <img src={getzipResposta}></img>
          </div>
          <div style={{textAlign:'center'}} className="col p-fluid flex flex-wrap">
            <h1>{resposta.informativo.opcoesPai}</h1>
            <h5>{resposta.informativo.opcoes}</h5>
          </div>
          <div style={{textAlign:'center'}} className="col p-fluid flex flex-wrap">
            {original && (<img src={quodImg} style={{width:'250px'}}></img>)}
          </div>
        </div>
      </Card>
      <br></br>
      <br></br>
      <Informativo informativo={resposta.informativo}></Informativo>
      <br></br>
      {resposta.avisos.length > 0 && (
        <Aviso avisos={resposta.avisos}></Aviso>  )}  
      <br></br>
      {resposta.dadosConsulta && (
        <DadosConsulta dadosConsulta={resposta.dadosConsulta}></DadosConsulta>  )}   
      {resposta.dadosConsultaPJ && (
        <DadosConsultaPJ capitalSocial={resposta.capitalSocial} dadosConsultaPJ={resposta.dadosConsultaPJ}></DadosConsultaPJ>  
      )}   
      {resposta.pessoa && (
        <> <br></br><Cadastral pessoa={resposta.pessoa}></Cadastral></>
          
      )}    
      {resposta.completaPF && (
        <CompletaPF completaPF={resposta.completaPF}></CompletaPF>  
      )} 
      {resposta.completaPJ && (
        <CompletaPJ completaPJ={resposta.completaPJ}></CompletaPJ>  
      )}    
      {resposta.score  && (
          <>
            <br></br>
            <Score scoreBoleto={false} label="Score" score={resposta.score} ></Score>
          </>
      )}
       {resposta.scoreBoletoPJ  && (
          <>
            <br></br>
            <Score scoreBoleto={true} label="Score Boleto PJ" score={resposta.scoreBoletoPJ} ></Score>
          </>
      )}
       {resposta.acoes && resposta.acoes.length > 0  && (
          <>
            <br></br>
            <Acoes acoes={resposta.acoes} ></Acoes>
          </>
      )}
       {resposta.acoesCompleta && resposta.acoesCompleta.length > 0  && (
          <>
            <br></br>
            <AcoesCompleta acoes={resposta.acoesCompleta} ></AcoesCompleta>
          </>
      )}
      {resposta.showProtesto && (
          <>
            <br></br>
            <Protestos cartorios={resposta.cartorios} ></Protestos>
          </>
      )}
      {resposta.showApontamento  && (
          <>
            <br></br>
            <Apontamentos apontamentos={resposta.apontamentos} ></Apontamentos>
          </>
      )}
       {resposta.protestosQuod && resposta.protestosQuod.length > 0  && (
          <>
            <br></br>
            <ProtestoQuod protestos={resposta.protestosQuod} ></ProtestoQuod>
          </>
      )}
       {resposta.pendencias && resposta.pendencias.length > 0  && (
          <>
            <br></br>
            <Pendencias pendencias={resposta.pendencias} ></Pendencias>
          </>
      )}
      {resposta.showPendencia > 0  && (
          <>
            <br></br>
            <Negativacoes negativacoes={resposta.negativacoes} ></Negativacoes>
          </>
      )}
       {resposta.ccfs && resposta.ccfs.length > 0  && (
          <>
            <br></br>
            <CCFS ccfs={resposta.ccfs} ></CCFS>
          </>
      )}
      {resposta.rendaPresumida  && (
          <>
            <br></br>
            <RendaPresumida rendaPresumida={resposta.rendaPresumida} ></RendaPresumida>
          </>
      )}
      {resposta.scoreGrauAtividadePJ  && (
          <>
            <br></br>
            <ScoreGrauAtvidadePJ scoreGrauAtividadePJ={resposta.scoreGrauAtividadePJ} ></ScoreGrauAtvidadePJ>
          </>
      )}
       {resposta.comportamentoFinanceiro  && (
          <>
            <br></br>
            <ComportamentoFinanceiro comportamento={resposta.comportamentoFinanceiro} ></ComportamentoFinanceiro>
          </>
      )}
      {resposta.linhaCredito  && (
          <>
            <br></br>
            <LinhaCredito linhaCredito={resposta.linhaCredito} ></LinhaCredito>
          </>
      )}
       {resposta.endividamentos && resposta.endividamentos.length > 0  && (
          <>
            <br></br>
            <Endividamento endividamentos={resposta.endividamentos} ></Endividamento>
          </>
      )}
      {resposta.pontualidadePagamento  && (
          <>
            <br></br>
            <PontualidadePagamento pontualidadePagamento={resposta.pontualidadePagamento} ></PontualidadePagamento>
          </>
      )}
      {resposta.historicoPagamento  && (
          <>
            <br></br>
            <HistoricoPagamento historicoPagamento={resposta.historicoPagamento} ></HistoricoPagamento>
          </>
      )}
      {resposta.historicoVencido && (
        <>
          <br></br>
          <HistoricoVencido
            historicoVencido={resposta.historicoVencido}
          ></HistoricoVencido>
        </>
      )}
      {resposta.limiteCreditoPJ  && (
          <>
            <br></br>
            <LimiteCreditoPJ limiteCreditoPJ={resposta.limiteCreditoPJ} ></LimiteCreditoPJ>
          </>
      )}
      {resposta.maisNegocio  && (
          <>
            <br></br>
            <MaisNegocio maisNegocio={resposta.maisNegocio} ></MaisNegocio>
          </>
      )}
      {resposta.faturamentosPresumido  && (
          <>
            <br></br>
            <FaturamentoPresumido faturamentos={resposta.faturamentosPresumido} ></FaturamentoPresumido>
          </>
      )}
      {resposta.consulta  && (
          <>
            <br></br>
            <ListaConsulta consulta={resposta.consulta} ></ListaConsulta>
          </>
      )}
      {resposta.empresas && resposta.empresas.length > 0 && (
          <>
            <br></br>
            <Empresas empresas={resposta.empresas} ></Empresas>
          </>
      )}
      {resposta.socios && resposta.socios.length > 0 && (
          <>
            <br></br>
            <Socios socios={resposta.socios} ></Socios>
          </>
      )}
      {resposta.participacoesEmpresa && resposta.participacoesEmpresa.length > 0 && (
          <>
            <br></br>
            <ParticipacaoEmpresa participacoes={resposta.participacoesEmpresa} ></ParticipacaoEmpresa>
          </>
      )}
      {resposta.enderecosHist && resposta.enderecosHist.length > 0  && (
          <>
            <br></br>
            <EnderecoHist enderecos={resposta.enderecosHist} ></EnderecoHist>
          </>
      )}
      {resposta.emailsHist && resposta.emailsHist.length > 0  && (
          <>
            <br></br>
            <HistoricoInfo showHist={true} label={t('email')} historicos={resposta.emailsHist} ></HistoricoInfo>
          </>
      )}
      {resposta.conglomerados && resposta.conglomerados.length > 0  && (
          <>
            <br></br>
            <HistoricoInfo showHist={false} label={t('conglomerados')} historicos={resposta.conglomerados} ></HistoricoInfo>
          </>
      )}
      {resposta.calularesHist && resposta.calularesHist.length > 0 && (
          <>
            <br></br>
            <HistoricoInfo showHist={true} label={t('celular')} historicos={resposta.calularesHist} ></HistoricoInfo>
          </>
      )}
      {resposta.telefonesHist && resposta.telefonesHist.length > 0 && (
          <>
            <br></br>
            <HistoricoInfo showHist={true} label={t('fone')} historicos={resposta.telefonesHist} ></HistoricoInfo>
          </>
      )}
      {resposta.analises && resposta.analises.length > 0  && (
          <>
            <br></br>
            <Analise analises={resposta.analises} ></Analise>
          </>
      )}
      {resposta.indicadoresPJ && resposta.indicadoresPJ.length > 0 && (
        <>
          <br></br>
          <IndicadorPJ indicadores={resposta.indicadoresPJ}></IndicadorPJ> </> )} 
      {resposta.indicadores && resposta.indicadores.length > 0 && (
        <>
          <br></br>
          <IndicadorNegocio indicadores={resposta.indicadores}></IndicadorNegocio> </> )} 
      
      <ScrollTop style={{marginBottom:'5rem'}} className="w-2rem h-2rem border-round bg-primary" icon="pi pi-arrow-up text-base" />
      <br></br>
      <Card style={{width:'98%'}}>
        <p style={{ fontSize: '10px', textAlign: 'center' }}>
          {rodapeMensagem}
        </p>
      </Card>
    </div>
    <br></br>
    <br></br>
    <Toolbar style={{width:'98%'}} start={startContent} end={endContent}/>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
  </>)
}
