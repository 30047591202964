
import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NadaConsta from "../../../components/nadaConsta";




export default function Protestos({
  cartorios
}: {
  cartorios: any
}) {
  const { t } = useTranslation();
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {cartorios && cartorios.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('protestos')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('protestos')}</span>)} </div>;
  }
  return (
    <Panel headerTemplate={headerTemplate} style={{width:'98%'}}>
       {(!cartorios || cartorios.length === 0) && ( <NadaConsta />)}
       {cartorios && cartorios.length > 0 && cartorios.map((cartorio:any) => {
        return (
          <>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('cartorio')}</label>
                    <Chip style={{width:"100%"}} label={cartorio.nome} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('quantidade')}</label>
                    <Chip style={{width:"100%"}} label={cartorio.quantidade} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('uf')}</label>
                    <Chip style={{width:"100%"}} label={cartorio.uf} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('fone')}</label>
                    <Chip style={{width:"100%"}} label={cartorio.telefone} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('endereco')}</label>
                    <Chip style={{width:"100%"}} label={cartorio.endereco} />
                </div>
              </div>
            </div>
            {cartorio.protestos ? (
              <>
                <br></br>
               
                <DataTable size="small" value={cartorio.protestos} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="data" header={t('data')}></Column>
                  <Column field="valor" header={t('valor')}></Column>
                </DataTable>
              <br></br>
              <br></br>
              <br></br>
              </>
            ):''}
          </>
        )
       })}
       
      
      
      
    </Panel>
  )
}
