
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function HistoricoPagamento({
  historicoPagamento
}: {
  historicoPagamento
  : any
}) {
  const { t } = useTranslation();

  return (
    <Panel header={t('historicoPagamento')} style={{width:'98%'}}>
       
       {historicoPagamento.columns && (
        <DataTable rowGroupMode="rowspan" groupRowsBy="doc" size="small" value={historicoPagamento.data} tableStyle={{ minWidth: '50rem' }}>
          {historicoPagamento.columns.map((col:any) => (
                      <Column key={col.field} field={col.field} header={col.header} />
                  ))}
        </DataTable>
       )}
       <p>{historicoPagamento.message}</p>
    </Panel>
  )
}
